
import { defineComponent } from "vue";

export default defineComponent({
  name: "GetMHTSection",
  data() {
    return {};
  },
  components: {},
  mounted() {},
  methods: {},
});
